import React, { Component } from "react"
import Layout from "../Layout/Layout/Layout"
import SEO from "../SEO/SEO"
import Header from "../Shop/Header/Header"
import Utils from "../../utils/node.utils"
import ReactMarkdown from "react-markdown"
import { Link } from "gatsby"

function getHeader(content) {
  if (content.header) {
    return (
      <h2 className="text-center text-uppercase mb-4 mt-5">{content.header}</h2>
    )
  }
  if (content.header2) {
    return <h3 className="">{content.header2}</h3>
  }
}

function getParagraphs(content) {
  if (content.paragraphs) {
    return content.paragraphs.map((item, i) => (
      <p className="" key={i}>
        {Utils.AllLinksToComponent(item, content.links)}
      </p>
    ))
  }
}

function flatten(text, child) {
  return typeof child === "string"
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text)
}

function HeadingRenderer(props) {
  let children = React.Children.toArray(props.children)
  let text = children.reduce(flatten, "")
  let slug = Utils.CreateSlug(text)
  let css = ""

  if (props.level == 2) {
    css = "text-center text-uppercase mt-4 mb-4"
  }

  if (props.level == 3) {
    css = "mt-2 mb-2"
  }

  return React.createElement(
    "h" + props.level,
    { id: slug, className: css },
    props.children
  )
}

function LinkRenderer(props) {
  const site = "https://lottamae.com"

  if (props.href.startsWith(site)) {
    const intLink = props.href.replace(site, "")
    return (
      <u>
        <Link to={intLink} title={intLink}>
          {props.children}
        </Link>
      </u>
    )
  }
  return (
    <u>
      <a href={props.href} title={props.href}>
        {props.children}
      </a>
    </u>
  )
}

function ListItemRenderer(props) {
  var children = React.Children.toArray(props.children)
  //let css = props.tight ? "markdown-bullets" : "markdown-no-bullets"
  let css = "markdown-bullets"
  var text = children.reduce(flatten, "")
  if (
    text.startsWith("a.") ||
    text.startsWith("b.") ||
    text.startsWith("c.") ||
    text.startsWith("d.") ||
    text.startsWith("e.") ||
    text.startsWith("f.") ||
    text.startsWith("g.")
  ) {
    css = "markdown-no-bullets"
  }

  return <li className={css}>{props.children}</li>
}

function ParagraphRenderer(props) {
  return <div className="mb-3 text-justify">{props.children}</div>
}

function getMarkdown(content) {
  if (content.markdown) {
    return (
      <ReactMarkdown
        children={content.markdown}
        components={{
          h1: HeadingRenderer,
          h2: HeadingRenderer,
          h3: HeadingRenderer,
          h4: HeadingRenderer,
          h5: HeadingRenderer,
          h6: HeadingRenderer,
          a: LinkRenderer,
          li: ListItemRenderer,
          p: ParagraphRenderer,
        }}
      />
    )
  }
}

function getComponent(content) {
  if (content.component) {
    return content.component
  }
}

function getContentPart(content) {
  return (
    <div className="container">
      <div className="row mb-3">
        <div className="col-0 col-lg-2"></div>
        <div className="col-12 col-lg-8 text-lg">
          {getHeader(content)}
          {getParagraphs(content)}
          {getMarkdown(content)}
          {getComponent(content)}
        </div>
        <div className="col-0 col-lg-2"></div>
      </div>
    </div>
  )
}

function getCarousel(img, overlay_text) {
  return (
    <div className="item home-full-item carousel-item-container-about">
      <img
        src={img.src}
        alt={img.altText}
        title={img.altText}
        className="carousel-image-about carousel-data-about"
      />
      <div className="container h-100 py-5">
        <div className="row align-items-end h-100">
          <div className="col-12 text-center mx-auto">
            <h1 className="mb-5 display-4 text-uppercase text-gray-700 font-weight-lighter text-serif">
              {overlay_text}
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default class StaticPage extends Component {
  render() {
    const {
      img,
      originPage,
      language,
      location,
      contentMap,
      commonContentMap,
      title,
      description,
      breadCrumbs,
      createContentFun,
      createOverlayFun,
      keywords,
    } = this.props

    return (
      <Layout
        origin={location.origin}
        originPage={originPage}
        language={language}
        commonContentMap={commonContentMap}
      >
        <SEO
          title={title}
          language={language}
          keywords={keywords}
          description={description}
          selfLink={originPage}
        />
        <Header breadCrumbs={breadCrumbs} commonContentMap={commonContentMap} />
        <StaticPageContent
          img={img}
          content={createContentFun(contentMap, commonContentMap, language)}
          overlay_text={createOverlayFun(contentMap)}
        ></StaticPageContent>
      </Layout>
    )
  }
}

class StaticPageContent extends Component {
  render() {
    const { img, content, overlay_text } = this.props
    return (
      <div>
        {getCarousel(img, overlay_text)}
        {content.map((item, i) => (
          <div key={i}>{getContentPart(item)}</div>
        ))}
      </div>
    )
  }
}
