import React from "react"
import Breadcrumb from "../../Shared/Breadcrumb/Breadcrumb"

export default function Header({ breadCrumbs, commonContentMap }) {
  return (
    <section className="hero">
      <div className="container">
        <div className="hero-content pb-0 text-center">
          <Breadcrumb
            breadcrumbs={breadCrumbs}
            commonContentMap={commonContentMap}
            position="center"
          />
        </div>
      </div>
    </section>
  )
}
