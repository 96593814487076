export function createOverlay(contentMap) {
  return contentMap.overlay.value
}

export function createContentList(contentMap) {
  return [
    {
      markdown: contentMap.markdown.value,
    },
  ]
}
