import React, { Component } from "react"
import { Link } from "gatsby"
import Utils from "../../../utils/node.utils"

export default class Breadcrumb extends Component {
  getBreadcrumb = (singleContentLink, index, len) => {
    const isLast = index == len - 1
    const sep = isLast ? "" : " / "
    const css = isLast
      ? "text-sm text-uppercase text-hover-dark text-dark active"
      : "text-sm text-uppercase text-hover-dark text-muted"

    return (
      <span className="text-muted" key={singleContentLink.value}>
        <Link
          className={css}
          to={singleContentLink.link}
          title={singleContentLink.value}
        >
          {singleContentLink.value}
        </Link>
        {sep}
      </span>
    )
  }

  render() {
    const { breadcrumbs, position, commonContentMap } = this.props
    const len = breadcrumbs.length

    return (
      <div className={"mt-4 mb-4 justify-content-" + position}>
        {breadcrumbs.map((breadcrumb, i) => {
          const singleContentLink = Utils.GetSingleContentLink(
            commonContentMap[breadcrumb]
          )
          return this.getBreadcrumb(singleContentLink, i, len)
        })}
      </div>
    )
  }
}

Breadcrumb.defaultProps = {
  breadcrumbs: [
    {
      name: "menu_home",
    },
  ],
  position: "center",
}
